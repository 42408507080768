var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('dmx-title',{attrs:{"bc-items":_vm.bcItems}},[_vm._v(_vm._s(_vm.$t('admin_dashboard_devices_title')))]),_c('b-row',{staticClass:"mb-1",attrs:{"align-v":"center"}},[_c('b-col',[_vm._v(" "+_vm._s(_vm.pagination.totalListCount)+" devices found "),_c('span',{staticClass:"ml-3"},[_vm._v(" Show: "),_c('b-link',{style:(((_vm.options.perPage == 10) ? 'font-weight: bold;' : ' ')),on:{"click":function($event){return _vm.setPerPage(10)}}},[_vm._v("10")]),_vm._v(" | "),_c('b-link',{style:(((_vm.options.perPage == 20) ? 'font-weight: bold;' : ' ')),on:{"click":function($event){return _vm.setPerPage(20)}}},[_vm._v("20")]),_vm._v(" | "),_c('b-link',{style:(((_vm.options.perPage == 1000) ? 'font-weight: bold;' : ' ')),on:{"click":function($event){return _vm.setPerPage(1000)}}},[_vm._v("All")])],1)]),_c('b-col',[_c('b-input',{attrs:{"loading":_vm.searchFieldIsLoading,"placeholder":_vm.$t('common_search')},on:{"input":function($event){return _vm.searchWithText()}},model:{value:(_vm.searchField),callback:function ($$v) {_vm.searchField=$$v},expression:"searchField"}})],1),_c('b-col',{attrs:{"align":"right"}},[_c('b-button',{attrs:{"variant":"success"},on:{"click":function($event){return _vm.newDevice()}}},[_c('b-icon',{attrs:{"icon":"plus"}}),_vm._v(" "+_vm._s(_vm.$t('common_create'))+" ")],1)],1)],1),_c('b-table',{attrs:{"id":"devices-table","thead-class":"dmx-thead-block","busy":_vm.loading,"outlined":"","striped":"","bordered":"","hover":"","items":_vm.deviceList,"fields":_vm.headers},on:{"row-clicked":_vm.editDevice},scopedSlots:_vm._u([{key:"cell(createdOnUtc)",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.utcFormatted(value))+" ")]}},{key:"cell(firmwareUpdateStatus)",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_c('b-button',{attrs:{"disabled":_vm.shouldDisableFirmwareUploadBtn(value)},on:{"click":function($event){return _vm.requestFirmwareUpdate(item)}}},[_vm._v(" "+_vm._s(_vm.readableFirmwareUpdateState(value))+" ")])]}},{key:"cell(deviceConnectedStatus)",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [(item.deviceConnectedStatus.connected)?_c('div',[(item.deviceConnectedStatus.connectedUpdatedAt !== '0001-01-01T00:00:00')?_c('div',[_c('label',{staticClass:"badge badge-success"},[_vm._v("Connected")]),(item.deviceConnectedStatus.connectedUpdatedAt)?_c('small',{staticClass:"ml-2"},[_c('b',[_vm._v("Updated at:")]),_vm._v(" "+_vm._s(_vm.utcFormatted(item.deviceConnectedStatus.connectedUpdatedAt)))]):_vm._e()]):_c('div',[_c('label',{staticClass:"badge badge-success"},[_vm._v("Connected")])])]):_c('div',[(item.deviceConnectedStatus.connectedUpdatedAt !== '0001-01-01T00:00:00')?_c('div',[_c('label',{staticClass:"badge badge-danger"},[_vm._v("Not connected")]),_c('small',{staticClass:"ml-2"},[_c('b',[_vm._v("Updated at:")]),_vm._v(" "+_vm._s(_vm.utcFormatted(item.deviceConnectedStatus.connectedUpdatedAt)))])]):_c('div',[_c('label',{staticClass:"badge badge-danger"},[_vm._v("Not Connected")])])])]}}])}),_c('div',[_c('b-pagination',{attrs:{"align":"center","total-rows":_vm.pagination.totalListCount,"per-page":_vm.options.perPage,"aria-controls":"devices-table"},model:{value:(_vm.options.currentPage),callback:function ($$v) {_vm.$set(_vm.options, "currentPage", $$v)},expression:"options.currentPage"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }